import React from 'react';
import './App.css';
import Routing from './routing';

function App() {
  return (
    <div className="App">
      < Routing  />
    </div>
  );
}

export default App;
